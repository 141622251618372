import { RouteObject } from "react-router-dom"; 
import { AuthRoute } from "@common/auth/guards/auth-route";
import {authGuard} from '@common/auth/guards/auth-route';
 

const lazyDriveRoute = async (
  cmp: keyof typeof import("@app/listing/listing-routes.lazy")
) => {
  const exports = await import("@app/listing/listing-routes.lazy");
  return {
    Component: exports[cmp],
  };
};

export const listingRoutes: RouteObject[] = [
  {
    path: "listing",
    loader: () => authGuard({permission: 'users.view'}),
    lazy: () => lazyDriveRoute("ListingLayout"),
    element: <AuthRoute />,
    children: [
      {
        index: true,
        lazy: () => lazyDriveRoute("ListingPageDatablePage"),
      },
      {
        path: "facebook-today",
        lazy: () => lazyDriveRoute("ListingPageFB3tagDatablePage"),
      },
      {
        path: "facebook-duplicate",
        lazy: () => lazyDriveRoute("ListingPageDatablePage"),
      },
      {
        path: "facebook-filter",
        lazy: () => lazyDriveRoute("ListingPageFB4uniqDatablePage"),
      },
      // {
      //   path: ":pageId/edit",
      //   lazy: () => lazyDriveRoute('InputPage3'),
      //   // lazy: () => lazyDriveRoute("EditCustomPage"),
      // },
      {
        path: "facebook-today/:pageId/edit",
        lazy: () => lazyDriveRoute('InputPage'),
        // lazy: () => lazyDriveRoute("EditCustomPage"),
      },
      {
        path: "facebook-duplicate/:pageId/edit",
        lazy: () => lazyDriveRoute('InputPage'),
        // lazy: () => lazyDriveRoute("EditCustomPage"),
      },
      {
        path: "facebook-filter/:pageId/edit",
        lazy: () => lazyDriveRoute('InputPage'),
        // lazy: () => lazyDriveRoute("EditCustomPage"),
      },
    ],
  },
];
